import getSSP from '../lib/getServerSideProps';
import PageComponent from '../components/Page';

const Index = (props) => {
  return <PageComponent {...props} />;
};

export default Index;

const _getServerSideProps = getSSP('page');

export async function getServerSideProps({ locale, previewData }) {
  return _getServerSideProps({ params: { uid: 'home' }, locale, previewData });
}
